<template>
  <el-dialog :title="title" :visible.sync="open" :modal-append-to-body="true" top="20px" width="40%"
    custom-class="addClass" :destroy-on-close="true" :before-close="handleClose">

    <template #default>

      <el-form ref="form" label-width="110px" :model="params" :rules="rules">
        <el-form-item label="宣教内容名称" prop="educationContentName">
          <el-input v-model="params.educationContentName" maxlength="30" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="所属医生" prop="docId">
          <el-select v-model="params.docId" clearable placeholder="请选择">
            <template v-for="(item, index) in doctorInfoList">
              <el-option :label="item.docName" :value="item.id" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分类" prop="videoClassificationInfoId">
          <div class="classify-box">
            <el-select v-model="params.videoClassificationInfoId" clearable placeholder="请选择">
              <template v-for="(item, index) in videoClassificationInfo">
                <el-option :label="item.classificationName" :value="item.videoClassificationInfoId"
                  :key="index"></el-option>
              </template>
            </el-select>
            <el-button type="text" size="small" @click="classifyFn">维护</el-button>
          </div>
        </el-form-item>
        <el-form-item label="内容类型" prop="contentType">
          <el-select v-model="params.contentType" clearable placeholder="请选择">
            <template v-for="(item, index) in educationContentDict">
              <el-option :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="所属单位" prop="subordinateUnit">
          <el-input v-model="params.subordinateUnit" maxlength="60" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" maxlength="300"
            show-word-limit v-model="params.introduction" />
        </el-form-item>
        <el-form-item label="播放数量" prop="playNum">
          <el-input v-model="params.playNum" oninput="value=value.replace(/[^0-9]/g,'')" clearable
            placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="点赞数量" prop="likeNum">
          <el-input v-model="params.likeNum" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="宣教内容上传" prop="videoUrl">
          <VideoUpload v-model="params.videoUrl" :showData="params.video" />
        </el-form-item>
        <el-form-item label="封面上传" prop="coverUrl" class="el-form-item-end">
          <ImageUpload v-model="params.coverUrl" :showData="params.image" />
        </el-form-item>
      </el-form>

      <Classify v-model="classify_show" @change="classify_change" />
    </template>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">取 消</el-button>
      <el-button type="primary" size="small" @click="onConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import VideoUpload from "@/components/PropagandaEducation/PropagandaEducation/components/VideoUpload";
import ImageUpload from "@/components/PropagandaEducation/PropagandaEducation/components/ImageUpload";
import Classify from "@/components/PropagandaEducation/PropagandaEducation/components/Classify";
export default {
  name: "index",
  components: {
    VideoUpload,
    ImageUpload,
    Classify,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    params: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      rules: {
        educationContentName: [
          { required: true, message: '请输入宣教内容名称', trigger: 'change' },
          // { min: 0, max: 30, message: '宣教内容名称长度在 0 到 30 位', trigger: 'blur' }
        ],
        docId: [
          { required: true, message: '请选择所属医生', trigger: 'change' }
        ],
        videoClassificationInfoId: [
          { required: true, message: '请选择所属分类', trigger: 'change' }
        ],
        contentType: [
          { required: true, message: '请选择内容类型', trigger: 'change' }
        ],
        playNum: [
          { required: true, message: '请输入播放数量', trigger: 'change' }
        ],
        likeNum: [
          { required: true, message: '请输入点赞数量', trigger: 'change' }
        ],
        videoUrl: [
          { required: true, message: '请上传宣教内容', trigger: 'change' }
        ],
        coverUrl: [
          { required: true, message: '请上传封面', trigger: 'change' }
        ],
      },
      classify_show: false,
      doctorInfoList: [], // 所属医生
      videoClassificationInfo: [], // 所属分类
      educationContentDict: [], // 内容类型
    }
  },
  computed: {
    title() {
      let str = '新建宣教内容'
      console.log(this.params);
      if (this.params.educationContentId) {
        str = '编辑宣教内容'
      }

      return str
    },
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value)
      }
    },
  },
  watch: {
    open: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal) {

      },
      immediate: true,
    },

  },
  created() {
    this.getDoctorInfoList();
    this.getVideoClassificationInfo();
    this.getEducationContentDict();
  },
  mounted() {

  },
  methods: {
    onConfirm() {
      console.log(this.params);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.onSubmit();
        }
      })
    },
    onCancel() {
      this.$refs["form"].resetFields();
      this.$emit("cancel");
      this.open = false;
    },
    onSubmit() {
      this.$post("ec/editeducationconten", this.params)
        .then(res => {
          console.log(res);
          // eslint-disable-next-line no-unused-vars
          let data = res.data;
          this.$refs["form"].resetFields();
          this.open = false;
          this.$emit("upDateFn");
        })
    },
    getEducationContentDict() {
      // 内容类型
      this.$get("ec/geteducationcontentdict")
        .then(res => {
          if (!res) return false;
          let data = res.data;
          console.log(data)
          this.educationContentDict = data;
        })
    },
    getVideoClassificationInfo() {
      // 所属分类
      return new Promise((resolve, reject) => {
        this.$get("ec/getvideoclassificationinfo")
          .then(res => {
            if (!res) return false;
            let data = res.data;
            console.log(data)
            this.videoClassificationInfo = data;
            resolve(true);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    getDoctorInfoList() {
      // 所属医生
      this.$get("ec/getdoctorinfolist")
        .then(res => {
          if (!res) return false;
          let data = res.data;
          this.doctorInfoList = data;
        })
    },
    classify_change(e) {
      this.getVideoClassificationInfo()
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          let arr = (this.videoClassificationInfo || []).filter(item => this.params.videoClassificationInfoId === item.videoClassificationInfoId);
          console.log(this.params.videoClassificationInfoId)
          console.log(this.videoClassificationInfo)
          console.log(arr);
          if (arr.length === 0) {
            this.params.videoClassificationInfoId = "";
          }
          this.classify_show = e;
        })
    },
    classifyFn() {
      this.classify_show = true;
    },
    handleClose(done) {
      this.$refs["form"].resetFields();
      this.$emit("cancel");
      done();
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .addClass {
  .el-dialog__header {
    background: rgba(242, 242, 242, 1);
  }
}

.dialog-footer {
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.classify-box {
  display: flex;
  align-items: center;

  .el-select+.el-button {
    margin-left: 10px;
  }
}

.el-select {
  width: 100%;
}

.el-form-item-end {
  margin-bottom: 0;
}
</style>
